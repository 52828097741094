var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c("carbon-dashboard", { attrs: { level: "user" } }),
      _c("project-kpis", { attrs: { level: "user" } }),
      _c("evolution-dashboard", { attrs: { level: "user" } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }