<template>
	<div class="animated fadeIn">
		<carbon-dashboard level="user" />
		<project-kpis level="user" />
		<evolution-dashboard level="user" />
	</div>
</template>

<script>
import CarbonDashboard from './dashboard/CarbonDashboard';
import EvolutionDashboard from './dashboard/EvolutionDashboard';
import ProjectKpis from './dashboard/ProjectKPIs';
// console.log("myDashboard")

export default {
	name: 'MyDashboard',
	components: {
		CarbonDashboard,
		EvolutionDashboard,
		ProjectKpis
	}
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
	width: 100% !important;
}
.white {
	height: 24px;
	background-color: 'white';
}
</style>
